import React from "react"

const BlockQuote = ({ data }) => {
  return (
    <div className="max-w-screen">
      <blockquote className="max-w-5xl pl-6 pr-6 font-italics text-white mx-auto text-sm leading-[3rem] md:leading-[5rem] break-words text-left">
        <p className="text-[2rem] md:text-[3rem] font-medium">{data.quoteBody}</p>
        {data.title && <cite className="mt-4 block not-italic font-bold font-display uppercase leading-5 text-xs tracking-[.2rem]">
          {data.title}
        </cite>}
      </blockquote>
    </div>
  )
}

export default BlockQuote
