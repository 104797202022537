import React from "react"
import { graphql } from "gatsby"
import BlockRichText from "./block-rich-text"
import BlockMedia from "./block-media"
import BlockQuote from "./block-quote"
import BlockSlider from "./block-slider"
import BlockWritingText from "./block-writing-text"
import { AnimatePresence, motion } from "framer-motion"

const componentsMap = {
  STRAPI__COMPONENT_SHARED_RICH_TEXT: BlockRichText,
  STRAPI__COMPONENT_SHARED_WRITING_TEXT: BlockWritingText,
  STRAPI__COMPONENT_SHARED_MEDIA: BlockMedia,
  STRAPI__COMPONENT_SHARED_QUOTE: BlockQuote,
  STRAPI__COMPONENT_SHARED_SLIDER: BlockSlider,
}

const Block = ({ block }) => {
  const Component = componentsMap[block.__typename]

  if (!Component) {
    return null
  }

  return <Component data={block} />
}

const blockVariants = {
  visible: { 
    opacity: 1,
    transition: { delay: 1, when: "beforeChildren", staggerChildren: .25 }
  },
  hidden: { 
    opacity: 0, 
    transition: { delay: 1, when: "afterChildren",  staggerChildren: .25 } 
  },
  exit: { 
    opacity: 0, 
    transition: { delay: 1, when: "afterChildren",  staggerChildren: .25 } 
  },
}

const blockChildrenVariants = {
  visible: { 
    opacity: 1,
    transition: { duration: .35 } 
  },
  hidden: { 
    opacity: 0, 
    transition: { duration: .35 } 
  },
}

const BlocksRenderer = ({ blocks }) => {
  return (
    <AnimatePresence>
      <motion.div initial="hidden" animate="visible" variants={blockVariants} className="flex flex-col gap-[2rem]">
        {blocks.map((block, index) => (
          block.__typename === "STRAPI__COMPONENT_SHARED_SLIDER" ?
            <motion.div variants={blockChildrenVariants} className=""><Block key={`${index}${block.__typename}`} block={block} /></motion.div> 
            : block.__typename === "STRAPI__COMPONENT_SHARED_RICH_TEXT" ?
              <motion.div variants={blockChildrenVariants} className="first:pt-[3.5rem] first:md:pt-[4rem] last:pb-[2rem]"><Block key={`${index}${block.__typename}`} block={block} /></motion.div> 
            : <motion.div variants={blockChildrenVariants} className="first:pt-[3.5rem] first:md:pt-[4rem]"><Block key={`${index}${block.__typename}`} block={block}  /></motion.div>    
        ))}
      </motion.div>
    </AnimatePresence>
  )
}

export const query = graphql`
  fragment Blocks on STRAPI__COMPONENT_SHARED_MEDIASTRAPI__COMPONENT_SHARED_QUOTESTRAPI__COMPONENT_SHARED_RICH_TEXTSTRAPI__COMPONENT_SHARED_SLIDERSTRAPI__COMPONENT_SHARED_WRITING_TEXTUnion {
    __typename
    ... on STRAPI__COMPONENT_SHARED_RICH_TEXT {
      richTextBody: body {
        __typename
        data {
          id
          childMarkdownRemark {
            html
          }
        }
      }
    }
    ... on STRAPI__COMPONENT_SHARED_WRITING_TEXT {
    writingTextBody: body {
      __typename
      data {
        id
        childMarkdownRemark {
          html
        }
      }
    }
  }
    ... on STRAPI__COMPONENT_SHARED_MEDIA {
      file {
        mime
        localFile {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
      caption
    }
    ... on STRAPI__COMPONENT_SHARED_QUOTE {
      title
      quoteBody: body
    }
    ... on STRAPI__COMPONENT_SHARED_SLIDER {
      files {
        id
        mime
        localFile {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
  }
`

export default BlocksRenderer
