import React from "react"
import { graphql } from "gatsby"
import Seo from "../components/seo"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Layout from "../components/layout"
import BlocksRenderer from "../components/blocks-renderer"
import ArticlesGrid from "../components/articles-grid"
import { useEffect, useState } from "react"
import { AnimatePresence, motion } from "framer-motion"
import { bgColors, bgHeroColors, bgHoverColors } from "../components/colors"

const ArticlePage = ({ data }) => {
  const article = data.strapiArticle
  const allStrapiArticle = data.allStrapiArticle

  const seo = {
    metaTitle: article.title,
    metaDescription: article.description,
    shareImage: article.cover,
  }

  const [modalOpen, setModalOpen] = useState(false);
  const [infoOpen, setInfoOpen] = useState(true);
  const [gridOpen, setGridOpen] = useState(true);

  useEffect(() => {
    window.addEventListener('scroll', setModalOpen())
  }, [])

  const bgHeroColors = {
    multimedia: `bg-multimedia-500/40`,
    art: `bg-art-400/50`,
    design: `bg-design-400/40`,
    writing: `bg-writing-500/40`,
    music: `bg-music-400/60`,
  }

  const bgColors = {
    multimedia: `bg-multimedia-500`,
    art: `bg-art-400`,
    design: `bg-design-400`,
    writing: `bg-writing-500`,
    music: `bg-music-400`,
  }

  const warningColors ={
    art: `text-art-300`
  }

  const bgHoverColors = {
    multimedia: `md:hover:bg-multimedia-500`,
    art: `md:hover:bg-art-400`,
    design: `md:hover:bg-design-400`,
    writing: `md:hover:bg-writing-500`,
    music: `md:hover:bg-music-400`,
  }

  const heroVariants = {
    visible: { 
      opacity: 1,
      transition: { delay: .75, duration: 1.5, when: "beforeChildren", staggerChildren: .25, ease: "easeInOut" }
    },
    hidden: { 
      opacity: 0, 
      transition: { delay: 0, duration: 1, when: "afterChildren",  staggerChildren: .25, ease: "easeInOut" } 
    },
    exit: { 
      opacity: 1,
      transition: { duration: 2.5, when: "beforeChildren",  staggerChildren: .25, ease: "easeInOut" }
    },
  }

  const headingVariants = {
    visible: { 
      opacity: 1,
      transition: { delay: .5, duration: 1.5, when: "beforeChildren", staggerChildren: .25, ease: "easeIn" }
    },
    hidden: { 
      opacity: 0, 
      transition: { delay: .5, duration: 1, when: "afterChildren",  staggerChildren: .25, ease: "easeInOut" } 
    },
    exit: { 
      opacity: 0, 
      transition: { delay: .5, duration: 1, when: "afterChildren",  staggerChildren: .25, ease: "easeInOut" } 
    },
  }

  const headingChildrenVariants = {
    visible: { 
      opacity: 1,
      transition: { duration: .5, ease: "easeInOut" } 
    },
    hidden: { 
      opacity: 0, 
      transition: { duration: .5, ease: "easeInOut" } 
    },
  }

  const footerVariants = {
    visible: { 
      opacity: 1,
      transition: { delay: 1.5, when: "beforeChildren", staggerChildren: .25, ease: "easeOut" }
    },
    hidden: { 
      opacity: 0, 
      transition: { delay: .5, when: "afterChildren",  staggerChildren: .25, ease: "easeInOut" } 
    },
    exit: { 
      opacity: 0, 
      transition: { delay: .5, when: "afterChildren",  staggerChildren: .25, ease: "easeInOut" } 
    },
  }

  const footerChildrenVariants = {
    visible: { 
      opacity: 1,
      transition: { duration: .5 } 
    },
    hidden: { 
      opacity: 0, 
      transition: { duration: .5 } 
    },
  }

  const modalVariants = {
    visible: { 
      opacity: 1,
      transition: { delay: .5, duration: 1.25, when: "beforeChildren", staggerChildren: .25 }
    },
    hidden: { 
      opacity: 0, 
      transition: { delay: 0, duration: .5, when: "afterChildren",  staggerChildren: .25 } 
    },
  }

  const modalChildrenVariants = {
    visible: { 
      opacity: 1,
      transition: { duration: 1, staggerChildren: 1 } 
    },
    hidden: { 
      opacity: 0,
      transition: { duration: 1, staggerChildren: .5 } 
    },
  }

  const childrenVariants = {
    visible: { 
      opacity: 1,
      transition: { delay: .5, duration: .5, ease: "easeInOut" } 
    },
    hidden: { 
      opacity: 0, 
      transition: { duration: 0, delay: 0, ease: "easeInOut"}
    },
  }

  const sectionVariants = {
    visible: {
      opacity: 1,
      height: "auto",
      transition: {duration: 1, delay: 0, ease: "easeInOut"}
    },
    hidden: {
      opcacity: 0,
      height: 0,
      transition: {duration: 1, delay: 0, ease: "easeInOut", when:"afterChildren"}
    }
  }

  const grayDropVariants = {
    visible: {
      opacity: 1,
      transition: {duration: 5, delay: 2, ease: "easeIn"}
    },
    hidden: {
      opcacity: 0,
      transition: {duration: 3, delay: 0, ease: "easeOut"}
    }
  }

  return (
    <Layout as="article" page={article.title} category={article.category} ismodalOpen={modalOpen}>
      <Seo seo={seo} />
      <AnimatePresence exitBeforeEnter>
        {!modalOpen &&
        <motion.div initial="hidden" animate="visible" exit="exit" variants={heroVariants} className="-z-50">
          <GatsbyImage
              image={article?.hero?.localFile ? getImage(article.hero.localFile) : getImage(article?.cover?.localFile)}
              alt={article?.heroImage?.alternativeText}
              className="w-screen h-screen fixed top-0"
            />
        </motion.div>}
      </AnimatePresence>
      <motion.footer initial="hidden" animate="visible" variants={footerVariants} className={`fixed bottom-0 z-50 px-4 py-6 flex flex-col justify-end place-content-center gap-5 w-screen h-screen pointer-events-none font-italics`}>
        {article.externalLink && <motion.a variants={footerChildrenVariants} whileHover={{ scale: 1.0 }} whileTap={{ scale: .99 }} className={`text-sm md:text-base min-w-[14ch] rounded px-2 py-1 shadow-md hover:shadow-lg pointer-events-auto text-center transition-colors duration-[200ms] text-white md:hover:text-white bg-black ${bgHoverColors[article.category]}`} href={article.externalLink}><span>{article.externalLinkLabel} <span class="font-mono">↗</span></span></motion.a>}
        <motion.button variants={footerChildrenVariants} whileHover={{ scale: 1.0 }} whileTap={{ scale: .99 }} className={`text-sm md:text-base rounded px-2 py-1 text-black bg-white ${bgHoverColors[article.category]} md:hover:text-white pointer-events-auto text-center shadow-md hover:shadow-lg transition-colors duration-[200ms]`} onClick={() => {setModalOpen(modalOpen => !modalOpen)}}><span className="text-center">{!modalOpen ? 'read more...' : 'read less...'}</span></motion.button>
      </motion.footer>
      <motion.header className={`font-display pb-6 pt-[3.5rem] px-6 z-8 w-screen h-screen transition-colors duration-[2500ms] fixed top-0 ${!modalOpen ? `${bgHeroColors[article.category]}` : `bg-gray-200`}`}>
        <AnimatePresence exitBeforeEnter>
          {!modalOpen && (
            <motion.div
              initial="hidden" 
              animate="visible"
              exit="hidden"
              variants={headingVariants} className={`mt-2`}>
              {article.inProgress === true ? <motion.div variants={headingChildrenVariants} className={`animate-pulse rounded border border-none text-white mb-3`}><span class="text-[1.25rem] leading-none pr-3 font-mono">⚑</span>in progress</motion.div> : `` }
              <motion.h1 
                variants={headingChildrenVariants}
                className="text-4xl md:text-5xl font-bold text-white break-words">
                  {article.title}
              </motion.h1>
              <motion.p 
                variants={headingChildrenVariants}
                className="mt-5 text-lg text-white break-words font-italics">
                  {article.description}
              </motion.p>
              <motion.div 
                variants={headingChildrenVariants}
                className="flex flex-wrap justify-start text-white gap-2 text-xs mt-4">
                  <div className={`rounded ${bgColors[article.category]} px-2 py-1 min-w-[7ch] text-center`}>{article.category}</div>
                  <div className="rounded border border-white line-clamp-2 px-2 py-1">{article.medium}</div>
                  {article.inProgress === true ? <div className="rounded border border-white px-2 py-1">{article.year} to present</div> : <div className="rounded border border-white px-2 py-1">{article.year}</div> }
              </motion.div>
            </motion.div>
            )}
        </AnimatePresence>
      </motion.header>
      <AnimatePresence exitBeforeEnter>
        {modalOpen && (
          <motion.main 
          className={`font-display min-h-screen min-w-screen ${bgColors[article.category]} flex flex-col justify-end absolute top-0 left-0 right-0 z-10`} 
          initial="hidden"
          animate="visible"
          exit="hidden"
          variants={modalVariants}>
            <motion.div
              variants={modalChildrenVariants}
              className="">
                <BlocksRenderer blocks={article.blocks || []}/>
            </motion.div>
            <motion.div variants={modalChildrenVariants} className="bg-white justify-end">
            <button className="px-4 py-2 text-sm text-white bg-black hover:text-white hover:bg-gray-500 transition-colors duration-[200ms] font-italics text-left w-full" onClick={() => {setInfoOpen(infoOpen => !infoOpen)}}>project info{infoOpen ? <span class='font-mono'> ↴</span> : `…`}</button>
              <AnimatePresence exitBeforeEnter>
                {infoOpen &&
                <motion.div initial="hidden"
                            animate="visible"
                          exit="hidden"
                          variants={sectionVariants}>
                  <motion.div initial="hidden"
                            animate="visible"
                          exit="hidden"
                          variants={childrenVariants} className="flex flex-col gap-5 mx-auto px-6 py-6">
                    <div className="flex-1">
                      <div className="font-italics text-sm">title</div>
                      <div className="text-2xl">{article.title}</div>
                    </div>
                    <div className="flex flex-col md:flex-row gap-4">
                      <div className="flex flex-col outline outline-1 rounded flex-[60%] h-full">
                      {article.inProgress === true ? <div className="border-b border-black px-3 py-3 bg-gray-200">
                          <div className="text-md text-xs md:text-sm max-w-[80rem] font-italics"><span className="pr-3 font">&#9936;</span><span className="font-bold pr-3">Workin' on it!</span>This project is still being updated.</div>
                        </div> : `` }
                        <div className="flex flex-row justify-evenly gap-5 px-3 py-3 flex-wrap">
                          <div className="">
                            <div className="font-italics text-center text-sm">year</div>
                            <div className="text-md text-center text-xs md:text-sm">{article.year} {article.inProgress === true ? `to present` : ``}</div>
                          </div>
                          <div className="">
                            <div className="font-italics text-center text-sm">medium</div>
                            <div className="text-md text-center text-xs md:text-sm">{article.medium}</div>
                          </div>
                          <div className="">
                            <div className="font-italics text-center text-sm">category</div>
                            <div className="text-md text-center text-xs md:text-sm">{article.category}</div>
                          </div>
                        </div>
                        {article.addInfo && <div className="border-t border-black px-3 py-3">
                          <div className="font-italics text-sm">additional information</div>
                          <div className="text-md text-xs md:text-sm max-w-[80rem]">{article.addInfo}</div>
                        </div>}
                        {article.itemsList && <div className="border-t border-black px-3 py-3 list-decimal">
                          <div className="font-italics text-sm">{article.itemsListTitle}</div>
                          <div className="text-md text-xs" dangerouslySetInnerHTML={{
                              __html: article.itemsList.data?.childMarkdownRemark?.html,
                          }}></div>
                        </div>}
                        {article.duration && <div className="border-t border-black px-3 py-3">
                            <div className="font-italics text-left text-sm">length</div>
                            <div className="text-md text-left text-xs md:text-sm">{article.duration}</div>
                        </div>}
                        {article.releasedOn && <div className="border-t border-black px-3 py-3">
                            <div className="font-italics text-left text-sm">released</div>
                            <div className="text-md text-left text-xs md:text-sm">{article.releasedOn}</div>
                        </div>}
                      </div>
                      {article.albumCover && <div className="flex flex-[40%] order-first md:order-last justify-center">
                        <GatsbyImage
                          image={article?.albumCover?.localFile ? getImage(article.albumCover.localFile) : getImage(article?.cover?.localFile)}
                          alt={article?.albumCover?.alternativeText}
                          className="max-h-[500px] max-w-[500px] md:mb-[5rem]"
                        />
                      </div>}
                  </div>
                  </motion.div>
               </motion.div>}
              </AnimatePresence>
            </motion.div>
            <motion.div variants={modalChildrenVariants} className={`bg-gray-200 flex-1 ${article.externalLink ? 'pb-[8rem] md:pb-[8rem]' : 'pb-[5rem] md:pb-[5rem]'}`}>
            <motion.button className="flex-0 px-4 py-2 text-sm text-white bg-black hover:text-white hover:bg-gray-500 transition-colors duration-[200ms] font-italics text-left w-full" onClick={() => {setGridOpen(gridOpen => !gridOpen)}}>other {article.category}{gridOpen ? <span class='font-mono'> ↴</span> : `…`}</motion.button>
              <AnimatePresence>
                {gridOpen &&
                <motion.div initial="hidden" animate="visible" exit="hidden" variants={sectionVariants}>
                  <motion.div initial="hidden" animate="visible" exit="hidden" variants={childrenVariants}>
                    {
                      allStrapiArticle.nodes.length > 0 ? <ArticlesGrid articles={allStrapiArticle.nodes} xLink={article.externalLink} /> : <div className="px-4 pt-6">
                        <div className={`rounded py-8 px-8 font-italics bg-gray-100 text-gray-500`}>nothing yet!</div>
                      </div>
                    }
                  </motion.div>
                </motion.div>
                }
              </AnimatePresence>
            </motion.div>
        </motion.main>
        )}
      </AnimatePresence>
    </Layout>
  )
}

export const pageQuery = graphql`
  query ($slug: String, $category: String) {
    allStrapiArticle(limit: 2, filter: {slug: {nin: [$slug, "test"]} category: {eq: $category}}) {
      nodes {
        id
        slug
        title
        description
        year
        medium
        category
        cover {
          alternativeText
          localFile {
          childImageSharp {
            gatsbyImageData(aspectRatio: 1.77, transformOptions: { grayscale: true })
            }
          }
        }
      }
    }
    strapiArticle(slug: { eq: $slug }) {
      id
      slug
      title
      year
      medium
      category
      description
      externalLink
      externalLinkLabel
      duration
      addInfo
      inProgress
      itemsList {
        data {
          childMarkdownRemark {
            html
          }
        }
      }
      itemsListTitle
      releasedOn(formatString: "MMMM DD, YYYY")
      blocks {
        ...Blocks
      }
      cover {
        alternativeText
        localFile {
          url
          childImageSharp {
            gatsbyImageData(transformOptions: { grayscale: true })
          }
        }
      }
      albumCover {
        alternativeText
        localFile {
          url
          childImageSharp {
            gatsbyImageData(transformOptions: { grayscale: false })
          }
        }
      }
      hero {
        alternativeText
        localFile {
        childImageSharp {
          gatsbyImageData(transformOptions: { grayscale: true })
          }
        }
      }
    }
  }
`

export default ArticlePage
