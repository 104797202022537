import React from "react"

const BlockWritingText = ({ data }) => {
  return (
    <div className="rounded mx-[2rem] mb-[2rem] px-[2rem] md:px-[4rem] py-[2rem] md:py-[4rem] bg-white text-neutral-900 prose prose-p:font-writing prose-headings:font-writing prose-headings:md:text-[3rem] prose-headings:text-[1.5rem] prose-headings:leading-[1.5rem] prose-headings:text-justify prose-headings:md:leading-[3rem] prose-p:leading-[1.5rem] prose-p:md:leading-[3rem] prose-p:text-justify prose-p:text-[1rem] prose-p:md:text-[2.5rem] prose-p:last-of-type:mb-0 prose-a:[3rem] prose-a:md:text-[4rem] prose-a:font-bold prose-a:font-italics prose-a:after:content-['_✳'] hover:prose-a:after:content-['_✺'] prose-a:decoration-[1px] prose-a:md:decoration-[1px] hover:prose-a:decoration-dashed prose-a:md:underline-offset-[.75rem] prose-a:underline-offset-[.5rem] hover:prose-a:no-underline mx-auto px-8 max-w-screen md:max-w-full break-words">
      <div
        dangerouslySetInnerHTML={{
          __html: data.writingTextBody.data.childMarkdownRemark.html,
        }}
      />
    </div>
  )
}

export default BlockWritingText
