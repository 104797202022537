import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const BlockMedia = ({ data }) => {
  const isVideo = data.file.mime.startsWith("video")

  return (
    <div className="px-4 md:px-8 py-4 md:py-8 first-of-type:pt-0 first-of-type:pb-4 first-of-type:md:pt-0 first-of-type:md:pb-8 flex place-content-center">
      {isVideo ? (
        <p>TODO video</p>
      ) : (
        <div className="flex flex-col gap-2 md:max-w-8xl md:min-w-3xl px-0 md:px-0 pt-0 md:pt-0 pb-2 bg-white">
          <GatsbyImage
            image={getImage(data.file.localFile)}
            alt={data.file.alternativeText}
          />
          {data.caption && <p className="px-[1rem] font-italics text-xs md:text-sm">↳ {data.caption}</p>}
        </div>
      )}
    </div>
  )
}

export default BlockMedia
