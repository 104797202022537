import React from "react"

const BlockRichText = ({ data }) => {
  return (
    <div className="text-black prose prose-black prose-headings:font-display prose-headings:md:text-[5rem] prose-headings:text-[2.5rem] prose-headings:leading-[3.5rem] prose-headings:md:leading-[7rem] prose-p:leading-[1.5rem] prose-p:md:leading-[2.5rem] prose-p:md:text-justify prose-p:text-left prose-p:text-[1rem] prose-p:md:text-[1.5rem] prose-p:md:max-w-6xl prose-p:md:mx-auto prose-h1:font-normal prose-h2:md:max-w-4xl prose-h2:md:mx-auto prose-h2:tracking-[.3rem] prose-h2:md:tracking-[.5rem] prose-h2:leading-normal prose-h2:md:leading-tight prose-h2:font-normal prose-h2:md:mb-5 prose-h2:mb-5 prose-h2:mt-[0rem] prose-h2:text-[1rem] prose-h2:md:text-[2rem] prose-h2:font-italics prose-h2:uppercase prose-h2:before:content-['_'] prose-h2:before:font-normal prose-h2:text-center prose-h2:before:font-sans prose-a:[3rem] prose-a:md:text-[4rem] prose-a:font-bold prose-a:font-italics prose-a:after:content-['_✳'] hover:prose-a:after:content-['_✺'] prose-a:decoration-[2px] prose-a:md:decoration-[5px] hover:prose-a:decoration-dashed prose-a:md:underline-offset-[.75rem] prose-a:underline-offset-[.75rem] hover:prose-a:no-underline mx-auto px-8 max-w-screen md:max-w-[85rem] break-words">
      <div
        className="rounded p-5 md:p-10 bg-white drop-shadow-lg"
        dangerouslySetInnerHTML={{
          __html: data.richTextBody.data.childMarkdownRemark.html,
        }}
      />
    </div>
  )
}

export default BlockRichText
