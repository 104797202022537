import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

const BlockSlider = ({ data }) => {
  return (
    <div>
      {/* <Slider
        infinite={true}
        centerMode={true}
        dots={true}
        speed={300}
        slidesToShow={1}
        slidesToScroll={1}
        arrows={true}
        swipe={true}
      > */}
        {data.files.map((file) => (
          <div className="max-w-screen">
            <GatsbyImage
              key={file.id}
              image={getImage(file.localFile)}
              alt={file.alternativeText}
              className="md:min-h-screen md:max-h-full w-full"
            />
          </div>
        ))}
      {/* </Slider> */}
    </div>
  )
}

export default BlockSlider
